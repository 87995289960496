import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';

//@ts-expect-error
import EditAccount from './components/EditAccount';

import createProfileEditStore from './createProfileEditStore.js';

declare global {
  interface Window {
    editAccount: (container: string, config: any) => void;
  }
}

window.editAccount = function (container: string, config: any) {
  const rootNode = document.querySelector(container);

  if (rootNode === null) {
    console.error(`[edit_account] Cannot render EditAccount; no node matched ${container} in querySelector`);
    return;
  }

  const store = createProfileEditStore({ config });

  // Don't refetch and rerender the component
  const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        refetchOnReconnect: true,
        retry: false,
        staleTime: twentyFourHoursInMs
      }
    }
  });

  ReactDOM.render(
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <EditAccount locale={config.locale} config={config} />
      </QueryClientProvider>
    </Provider>,
    rootNode
  );
};
